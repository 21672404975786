<template>
  <div class="nav-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/index' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/index' }">应用中心</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/index' }">会议壳</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/meeting-list' }">会议列表</el-breadcrumb-item>
      <el-breadcrumb-item>{{this.$route.query.itemName}}</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/content/index/' + this.$route.params.mId }">会议内容管理</el-breadcrumb-item>
      <el-breadcrumb-item>导航菜单</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <div class="nav-content">
        <div class="left">
          <div class="preview-panel" :style="'background-image: url(' + meetingInfo.bg_img + ')'">
            <div class="header-area">
              <img :src="meetingInfo.head_img" v-if="meetingInfo.head_layout === 1"/>
              <div v-html="meetingInfo.head_txt" v-if="meetingInfo.head_layout === 2" :style="'color:' + meetingInfo.head_txt_color"></div>
            </div>
            <div class="nav-list">
              <div class="nav-item" v-for="item in navList" :key="item.id" :index="item.sort">
                <div class="nav-img">
                  <img :src="item.icon"/>
                </div>
                <span class="nav-title">{{item.title}}</span>
                <div class="operation">
                  <div class="edit" @click="editNav(item.id)">
                    <i class="el-icon-edit"></i>
                  </div>
                  <div class="delete" @click="delNav(item.id)">
                    <i class="el-icon-delete"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="copyright" :style="'color:' + meetingInfo.copyright_color">{{meetingInfo.copyright}}</div>
            <div class="music" @click="musicplay" :class="{'rotating' : musicData.isrotating}" :style="'opacity:' + this.musicData.opacity">
              <div class="bgm-btn rotate" style="animation-play-state: paused;" ref="bgmBtn">
                <audio :src="meetingInfo.bg_music" loop autoplay ref="audio" controls="controls" preload hidden></audio>
              </div>
            </div>
          </div>
          <div class="page-type-list">
            <div class="type-item">
              <el-button type="success" size="mini" @click="pageType = 1">新增导航</el-button>
            </div>
            <div class="type-item" v-if="pageType === 1">
              <el-button type="danger" size="mini" @click="pageType = 0">取消添加</el-button>
            </div>
            <div class="type-item" v-if="pageType === 2">
              <el-button type="danger" size="mini" @click="pageType = 0">取消修改</el-button>
            </div>
          </div>
        </div>
        <div class="right" v-if="pageType === 0">
          <div class="page-tips">
            <el-alert title="将鼠标移动至左侧导航图标上，即可显示编辑和删除按钮" type="warning" effect="dark"></el-alert>
          </div>
        </div>
        <!-- 新增模式 -->
        <div class="right" v-if="pageType === 1">
          <el-steps :active="activeStep" align-center finish-status="success">
            <el-step title="基础信息"></el-step>
            <el-step title="页面类型"></el-step>
            <el-step title="绑定数据"></el-step>
          </el-steps>
          <el-form label-position="top" :model="addForm" :rules="addRules" ref="addRef" label-width="auto" class="add-form">
            <div class="step-content" v-show="activeStep === 0">
              <div class="form-content">
                <el-form-item label="排序" prop="sort">
                  <el-input-number size="medium" v-model="addForm.sort" :min="0" :step-strictly="true"></el-input-number>
                </el-form-item>
                <el-form-item label="导航菜单标题" prop="title">
                  <el-input v-model="addForm.title"></el-input>
                </el-form-item>
                <el-form-item label="导航图标" prop="icon">
                  <upload :url="addForm.icon" :filed="'icon'" :max-num="1" @checked-files="checkedFiles" @del-select="delSelect"></upload>
                  <div class="tips">
                    您也可以
                    <span class="point-text" @click="iconVisible = true">点击此处</span>
                    ，快速选择系统内置的导航图标
                  </div>
                </el-form-item>
              </div>
              <el-form-item class="step-btn-area">
                <el-button @click="activeStep = 1" type="primary">下一步</el-button>
              </el-form-item>
            </div>
            <div class="step-content" v-show="activeStep === 1">
              <div class="form-content">
                <el-form-item label="是否为外部链接" prop="is_out">
                  <el-radio-group v-model="addForm.is_out">
                    <el-radio :label="1">内部页面</el-radio>
                    <el-radio :label="2">外部链接</el-radio>
                  </el-radio-group>
                </el-form-item>
                <div class="h-tips" v-if="addForm.is_out === 2">
                  当前外部链接仅支持关联的公众号文章，其它网页链接需联系管理员处理。
                </div>
                <el-form-item label="页面类型" prop="type" v-if="addForm.is_out === 1">
                  <el-radio-group v-model="addForm.type">
                    <el-radio label="contacts">联系人</el-radio>
                    <el-radio label="traffic">交通路线</el-radio>
                    <el-radio label="guest">会议嘉宾</el-radio>
                    <el-radio label="article">文章</el-radio>
                    <el-radio label="schedule">日程</el-radio>
                    <el-radio label="live">会议直播</el-radio>
                    <el-radio label="video">会议视频</el-radio>
                    <el-radio label="photos">图片直播</el-radio>
                    <el-radio label="clockin">拍照打卡</el-radio>
                    <el-radio label="download">文件下载</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="页面内容形式" prop="page_type" v-if="addForm.is_out === 1">
                  <el-radio-group v-model="addForm.page_type">
                    <el-radio :label="1">单内容</el-radio>
                    <el-radio :label="2">列表页面</el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>
              <el-form-item class="step-btn-area">
                <el-button @click="activeStep = 0" type="warning">上一步</el-button>
                <el-button @click="skipLast" type="primary">下一步</el-button>
              </el-form-item>
            </div>
            <div class="step-content" v-show="activeStep === 2">
              <div class="form-content">
                <el-form-item label="展示数据" prop="query" v-if="addForm.is_out === 1 && addForm.page_type === 2">
                  <el-select v-model="addForm.query" multiple filterable default-first-option placeholder="请选择需要展示的内容">
                    <el-option v-for="item in bindList" :key="item.id" :label="item.title" :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="展示数据" prop="query" v-if="addForm.is_out === 1 && addForm.page_type === 1">
                  <el-select v-model="addForm.query" filterable default-first-option placeholder="请选择需要展示的内容">
                    <el-option v-for="item in bindList" :key="item.id" :label="item.title" :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="跳转链接地址" prop="link" v-if="addForm.is_out === 2">
                  <el-input v-model="addForm.link"></el-input>
                </el-form-item>
              </div>
              <el-form-item class="step-btn-area">
                <el-button @click="activeStep = 1" type="warning">上一步</el-button>
                <el-button @click="submitForm" type="success">提 交</el-button>
              </el-form-item>
            </div>
          </el-form>
        </div>
        <!-- 编辑模式 -->
        <div class="right" v-if="pageType === 2">
          <el-steps :active="activeEditStep" align-center finish-status="success">
            <el-step title="基础信息"></el-step>
            <el-step title="页面类型"></el-step>
            <el-step title="绑定数据"></el-step>
          </el-steps>
          <el-form label-position="top" :model="editForm" :rules="editRules" ref="editRef" label-width="auto" class="edit-form">
            <div class="step-content" v-show="activeEditStep === 0">
              <div class="form-content">
                <el-form-item label="排序" prop="sort">
                  <el-input-number size="medium" v-model="editForm.sort" :min="0" :step-strictly="true"></el-input-number>
                </el-form-item>
                <el-form-item label="导航菜单标题" prop="title">
                  <el-input v-model="editForm.title"></el-input>
                </el-form-item>
                <el-form-item label="导航图标" prop="icon">
                  <upload :url="editForm.icon" :filed="'icon'" :max-num="1" @checked-files="checkedEditFiles" @del-select="delEditSelect"></upload>
                  <div class="tips">
                    您也可以
                    <span class="point-text" @click="iconEditVisible = true">点击此处</span>
                    ，快速选择系统内置的导航图标
                  </div>
                </el-form-item>
              </div>
              <el-form-item class="step-btn-area">
                <el-button @click="activeEditStep = 1" type="primary">下一步</el-button>
              </el-form-item>
            </div>
            <div class="step-content" v-show="activeEditStep === 1">
              <div class="form-content">
                <el-form-item label="是否为外部链接" prop="is_out">
                  <el-radio-group v-model="editForm.is_out">
                    <el-radio :label="1">内部页面</el-radio>
                    <el-radio :label="2">外部链接</el-radio>
                  </el-radio-group>
                </el-form-item>
                <div class="h-tips" v-if="editForm.is_out === 2">
                  当前外部链接仅支持关联的公众号文章，其它网页链接需联系管理员处理。
                </div>
                <el-form-item label="页面类型" prop="type" v-if="editForm.is_out === 1">
                  <el-radio-group v-model="editForm.type">
                    <el-radio label="contacts">联系人</el-radio>
                    <el-radio label="traffic">交通路线</el-radio>
                    <el-radio label="guest">会议嘉宾</el-radio>
                    <el-radio label="article">文章</el-radio>
                    <el-radio label="schedule">日程</el-radio>
                    <el-radio label="live">会议直播</el-radio>
                    <el-radio label="video">会议视频</el-radio>
                    <el-radio label="photos">图片直播</el-radio>
                    <el-radio label="clockin">拍照打卡</el-radio>
                    <el-radio label="download">文件下载</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="页面内容形式" prop="page_type" v-if="editForm.is_out === 1">
                  <el-radio-group v-model="editForm.page_type">
                    <el-radio :label="1">单内容</el-radio>
                    <el-radio :label="2">列表页面</el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>
              <el-form-item class="step-btn-area">
                <el-button @click="activeEditStep = 0" type="warning">上一步</el-button>
                <el-button @click="skipEditLast" type="primary">下一步</el-button>
              </el-form-item>
            </div>
            <div class="step-content" v-show="activeEditStep === 2">
              <div class="form-content">
                <el-form-item label="展示数据" prop="query" v-if="editForm.is_out === 1 && editForm.page_type === 2">
                  <el-select v-model="editForm.query" multiple filterable default-first-option placeholder="请选择需要展示的内容">
                    <el-option v-for="item in bindList" :key="item.id" :label="item.title" :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="展示数据" prop="query" v-if="editForm.is_out === 1 && editForm.page_type === 1">
                  <el-select v-model="editForm.query" filterable default-first-option placeholder="请选择需要展示的内容">
                    <el-option v-for="item in bindList" :key="item.id" :label="item.title" :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="跳转链接地址" prop="link" v-if="editForm.is_out === 2">
                  <el-input v-model="editForm.link"></el-input>
                </el-form-item>
              </div>
              <el-form-item class="step-btn-area">
                <el-button @click="activeEditStep = 1" type="warning">上一步</el-button>
                <el-button @click="submitEditForm" type="success">提 交</el-button>
              </el-form-item>
            </div>
          </el-form>
        </div>
      </div>
    </div>
    <!-- 公共图标模板选择弹窗(添加) -->
    <el-dialog title="公共图标" :visible.sync="iconVisible" width="50%">
      <div class="icon-list">
        <div class="icon-item" v-for="item in iconList" :key="item.id" @click="selectIconItem(item.url)">
          <el-image :src="item.url" lazy></el-image>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="iconVisible = false">关闭</el-button>
      </span>
    </el-dialog>
    <!-- 公共图标模板选择弹窗(编辑) -->
    <el-dialog title="公共图标" :visible.sync="iconEditVisible" width="50%">
      <div class="icon-list">
        <div class="icon-item" v-for="item in iconList" :key="item.id" @click="selectEditIconItem(item.url)">
          <el-image :src="item.url" lazy></el-image>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="iconEditVisible = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Upload from '../../../../common/Upload'
export default {
  name: 'index',
  components: {
    Upload
  },
  data () {
    return {
      // 页面模式 0 未知 1添加模式  2编辑模式
      pageType: 0,
      activeStep: 0,
      activeEditStep: 0,
      musicData: {
        isrotating: false,
        opacity: 0.4
      },
      meetingInfo: {
        title: '',
        start_time: '',
        end_time: '',
        copyright: '',
        is_register: 2,
        auto_check: 1,
        auto_ticket: 1,
        bg_img: '',
        head_layout: 1,
        head_txt: '',
        head_img: '',
        head_txt_color: 'rgba(0,0,0,1)',
        nav_color: 'rgba(0,0,0,1)',
        copyright_color: 'rgba(0,0,0,1)',
        bg_music: '',
        wx_img: '',
        wx_title: '',
        wx_content: ''
      },
      addForm: {
        mId: this.$route.params.mId,
        sort: 0,
        title: '',
        icon: '',
        type: 'contacts',
        page_type: 1,
        query: null,
        is_out: 1,
        link: ''
      },
      addRules: {
        title: [
          { required: true, message: '请填写导航菜单名称', trigger: 'blur' },
          { min: 1, max: 8, message: '长度在 1 到 8 个字符', trigger: 'blur' }
        ],
        icon: [
          { required: true, message: '请上传导航图标', trigger: 'blur' }
        ]
      },
      editForm: {
        id: 0,
        sort: 0,
        title: '',
        icon: '',
        type: 'contacts',
        page_type: 1,
        query: null,
        is_out: 1,
        link: ''
      },
      editRules: {
        title: [
          { required: true, message: '请填写导航菜单名称', trigger: 'blur' },
          { min: 1, max: 8, message: '长度在 1 到 8 个字符', trigger: 'blur' }
        ],
        icon: [
          { required: true, message: '请上传导航图标', trigger: 'blur' }
        ]
      },
      // 快速选择导航图标
      iconVisible: false,
      // 编辑模式快速选择导航图标
      iconEditVisible: false,
      // 公共背景列表
      iconList: [],
      // 选择绑定的内容ID数组
      bindList: [],
      // 导航列表
      navList: []
    }
  },
  created () {
    // 获取信息
    // 获取导航图标列表
    this.getIconList()
    // 获取默认的数据列表信息
    this.getBindData('contacts')
    // 获取导航列表
    this.getNavList()
  },
  methods: {
    // 获取导航列表
    async getNavList () {
      const { data: res } = await this.$http.get('/shell-nav-list', { params: { mId: this.$route.params.mId } })
      if (res.status === 200) {
        this.navList = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    musicplay () {
      if (this.$refs.audio.paused && this.meetingInfo.bg_music) {
        this.musicData.isrotating = true
        this.musicData.opacity = 1
        window.sessionStorage.setItem('music', 'true')
        this.$refs.audio.play()
      } else {
        this.musicData.isrotating = false
        this.musicData.opacity = 0.4
        window.sessionStorage.setItem('music', 'false')
        this.$refs.audio.pause()
      }
    },
    // 获取展示数据列表
    async getBindData (tid) {
      const { data: res } = await this.$http.get('/shell-bind-data-list', { params: { m_id: this.$route.params.mId, type: this.addForm.type } })
      if (res.status === 200) {
        this.bindList = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    // 获取展示数据列表
    async getEditBindData (tid) {
      const { data: res } = await this.$http.get('/shell-bind-data-list', { params: { m_id: this.$route.params.mId, type: this.editForm.type } })
      if (res.status === 200) {
        this.bindList = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    // 上传组件返回值
    checkedFiles (data) {
      this.addForm[data.filed] = data.url
    },
    // 删除已选择文件
    delSelect (field, item, pattern) {
      // 单文件删除
      if (pattern === 'single') {
        this.addForm[field] = ''
      } else {
        // 多文件删除
        const checkedIndex = this.addForm[field].findIndex(fileItem => fileItem === item)
        // 如果已包含了该文件则移除文件 和 checked 样式
        this.addForm[field].splice(checkedIndex, 1)
      }
    },
    // 上传组件返回值(编辑)
    checkedEditFiles (data) {
      this.editForm[data.filed] = data.url
    },
    // 删除已选择文件(编辑)
    delEditSelect (field, item, pattern) {
      // 单文件删除
      if (pattern === 'single') {
        this.editForm[field] = ''
      } else {
        // 多文件删除
        const checkedIndex = this.editForm[field].findIndex(fileItem => fileItem === item)
        // 如果已包含了该文件则移除文件 和 checked 样式
        this.editForm[field].splice(checkedIndex, 1)
      }
    },
    // 获取公共背景模板
    async getIconList () {
      const { data: res } = await this.$http.get('/shell-icon-list')
      if (res.status === 200) {
        this.iconList = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    // 选中某一个图标
    selectIconItem (url) {
      this.addForm.icon = url
      this.iconVisible = false
    },
    // 编辑模式选中某一个图标
    selectEditIconItem (url) {
      this.editForm.icon = url
      this.iconEditVisible = false
    },
    // 第二步像第三步跳转
    skipLast () {
      // 先检查第一步第二步参数是否已填写
      if (!this.addForm.title) {
        this.$message.error('请填写导航菜单名称')
        this.activeStep = 0
        return false
      }
      if (!this.addForm.icon) {
        this.$message.error('请上传或选择导航图标')
        this.activeStep = 0
        return false
      }
      this.activeStep = 2
    },
    // 第二步像第三步跳转
    skipEditLast () {
      // 先检查第一步第二步参数是否已填写
      if (!this.editForm.title) {
        this.$message.error('请填写导航菜单名称')
        this.activeEditStep = 0
        return false
      }
      if (!this.editForm.icon) {
        this.$message.error('请上传或选择导航图标')
        this.activeEditStep = 0
        return false
      }
      this.activeEditStep = 2
    },
    // 提交添加表单
    submitForm () {
      this.$refs.addRef.validate(async (valid, item) => {
        if (valid) {
          const { data: res } = await this.$http.post('/shell-meeting-nav/0', this.addForm)
          if (res.status === 200) {
            this.$message.success(res.msg)
            this.$refs.addRef.resetFields()
            await this.getNavList()
            this.pageType = 0
            this.activeStep = 0
          } else {
            this.$message.error(res.msg)
          }
        } else {
          this.$message.error('请检查表单必填项')
          return false
        }
      })
    },
    // 提交编辑表单
    submitEditForm () {
      this.$refs.editRef.validate(async (valid, item) => {
        if (valid) {
          const { data: res } = await this.$http.put('/shell-meeting-nav/' + this.editForm.id, this.editForm)
          if (res.status === 200) {
            this.$message.success(res.msg)
            this.$refs.editRef.resetFields()
            await this.getNavList()
            this.pageType = 0
            this.activeEditStep = 0
          } else {
            this.$message.error(res.msg)
          }
        } else {
          this.$message.error('请检查表单必填项')
          return false
        }
      })
    },
    // 编辑某个导航
    async editNav (id) {
      this.pageType = 0
      this.activeEditStep = 0
      const { data: res } = await this.$http.get('/shell-meeting-nav/' + id)
      if (res.status === 200) {
        this.editForm.id = res.data.id
        this.editForm.sort = res.data.sort
        this.editForm.title = res.data.title
        this.editForm.icon = res.data.icon
        this.editForm.type = res.data.type
        this.editForm.page_type = Number(res.data.page_type)
        this.editForm.query = res.data.query
        this.editForm.is_out = res.data.is_out
        this.editForm.link = res.data.link
        this.pageType = 2
      } else {
        this.$message.error(res.msg)
      }
    },
    // 删除某个导航
    async delNav (id) {
      const { data: res } = await this.$http.delete('/shell-meeting-nav/' + id)
      if (res.status === 200) {
        await this.getNavList()
      } else {
        this.$message.error(res.msg)
      }
    }
  },
  watch: {
    'addForm.type' (newValue) {
      // 判断is_out是否为系统内部链接
      if (this.addForm.is_out === 1) {
        this.getBindData(newValue)
      } else {
        this.addForm.query = null
        this.bindList = []
      }
    },
    'addForm.page_type' (newValue) {
      // 判断页面类型是列表页面还是单页面
      if (newValue === 1) {
        this.addForm.query = ''
      } else {
        this.addForm.query = []
      }
    },
    'editForm.type' (newValue) {
      // 判断is_out是否为系统内部链接
      if (this.editForm.is_out === 1) {
        this.getEditBindData(newValue)
      }
    },
    'editForm.page_type' (newValue) {
      if (newValue === 1 && ((typeof this.editForm.query) !== 'string')) {
        this.editForm.query = ''
      } else if (newValue === 2 && this.editForm.query.constructor !== Array) {
        this.editForm.query = []
      }
    }
  }
}
</script>

<style scoped>
.page-content{
  text-align: left;
}
.nav-content{
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
}
.nav-content .left{
  width: 360px;
  position: relative;
}
.nav-content .right{
  flex: 1;
  max-width: 420px;
}
.nav-content .left .preview-panel{
  background-color: rgba(0,0,0,0.1);
  height: calc(100vh - 324px);
  position: relative;
  background-size: cover;
  overflow-y: auto;
}
/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
.nav-content .left .preview-panel::-webkit-scrollbar{
  width: 7px;
  height: 7px;
  background-color: #d3dce6;
}

/*定义滚动条轨道 内阴影+圆角*/
.nav-content .left .preview-panel::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(64,158,255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(64,158,255, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

/*定义滑块 内阴影+圆角*/
.nav-content .left .preview-panel::-webkit-scrollbar-thumb{
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(64,158,255, .1);
  -webkit-box-shadow: inset 0 0 6px rgba(64,158,255, .1);
  background-color: rgb(19, 206, 102);
}
.nav-content .left .preview-panel .header-area{}
.nav-content .left .preview-panel .header-area img{
  width: 100%;
}
.nav-content .left .preview-panel .header-area div{
  padding: 20px;
  word-break: break-all;
}
.nav-content .left .preview-panel .copyright{
  position: relative;
  bottom: 10px;
  left: 0;
  width: 100%;
  word-break: break-all;
}
.nav-content .left .preview-panel .music{
  position: absolute;
  top: 14px;
  right: 14px;
  width: 40px;
  height: 40px;
}
.nav-content .left .preview-panel .music img{
  width: 100%;
  height: 100%;
}
.step-content{
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(255,255,255,0.5);
  position: relative;
  margin-top: 0;
  padding: 10px;
}
.step-content .form-content{
  height: calc(100vh - 471px);
  overflow-y: auto;
  padding: 10px;
  box-sizing: border-box;
}
/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
.step-content .form-content::-webkit-scrollbar{
  width: 7px;
  height: 7px;
  background-color: #d3dce6;
}

/*定义滚动条轨道 内阴影+圆角*/
.step-content .form-content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(64,158,255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(64,158,255, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

/*定义滑块 内阴影+圆角*/
.step-content .form-content::-webkit-scrollbar-thumb{
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(64,158,255, .1);
  -webkit-box-shadow: inset 0 0 6px rgba(64,158,255, .1);
  background-color: rgb(19, 206, 102);
}
.step-content .step-btn-area{
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(255,255,255,1);
  text-align: center;
  padding: 14px;
  margin-bottom: 0;
}
/deep/.el-step__title{
  font-size: 13px;
}
.music{
  position: fixed;
  top: 20px;
  right: 20px;
  width: 30px;
  height: 30px;
  z-index: 200;
  border-radius: 15px;
}
.rotating{
  -webkit-animation: rotating 1.2s linear infinite;
  animation: rotating 1.2s linear infinite;
}
.music .bgm-btn{
  width: 100%;
  height: 100%;
  background-image: url('../../../../../assets/img/shell/music.png');
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 15px;
}
.copyright{
  text-align: center;
  font-size: 13px;
}
@keyframes rotating {
  from {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(1turn)
  }
}
.el-form-item .tips{
  font-size: 13px;
  color: #909399;
}
.el-form-item .tips .point-text{
  color: #F56C6C;
  cursor: pointer;
}
.icon-list{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-height: 520px;
  overflow-y: auto;
}
.icon-list .icon-item{
  width: 100px;
  height: 100px;
  margin: 20px;
  cursor: pointer;
  padding: 10px;
  background-color: #FFFFFF;
}
.icon-item:hover{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
/deep/.el-dialog__body{
  background-color: #F2F6FC;
}
.step-btn-area{
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(255,255,255,1);
  text-align: center;
  padding: 14px;
  margin-bottom: 0;
}
.el-radio{
  margin-bottom: 10px;
}
.preview-panel .nav-list{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 8px 8px 60px 8px;;
}
.preview-panel .nav-list .nav-item{
  width: 108px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2px;
  padding: 4px;
  position: relative;
  transition: background-color 0.5s linear;
}
.preview-panel .nav-list .nav-item:hover{
  background-color: #FFFFFF;
  border-radius: 6px;
}
.preview-panel .nav-list .nav-item .nav-img{
  width: 80px;
  height: 80px;
  padding: 10px;
}
.preview-panel .nav-list .nav-item .nav-img img{
  width: 100%;
  height: 100%;
}
.preview-panel .nav-list .nav-item .operation{
  display: none;
}
.preview-panel .nav-list .nav-item:hover .operation{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  background-color: rgba(48, 49, 51, 0.8);
  border-radius: 6px;
}
.preview-panel .nav-list .nav-item:hover .operation .edit{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
}
.edit i {
  background-color: #67C23A;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}
.preview-panel .nav-list .nav-item:hover .operation .delete{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
}
.delete i {
  background-color: #F56C6C;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}
.preview-panel .nav-list .nav-item span{
  font-size: 13px;
  width: 70px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: auto;
  margin-right: auto;
}
.page-type-list{
  position: absolute;
  top: 0;
  right: -86px;
}
.page-type-list .type-item{
  margin-bottom: 10px;
}
.page-tips{
  height: calc(100vh - 471px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.h-tips{
  background-color: #F56C6C;
  color: #FFFFFF;
  font-size: 14px;
  padding: 10px;
}
/deep/.el-form--label-top .el-form-item__label{
  padding: 0;
}
</style>
